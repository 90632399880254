<div class="home">
  <section>
    <h1>Bienvenid@ {{usuario.nombre}}</h1>
    <div class="home-flex">
      <article id="home-bonos" class="home-card big image-fondo">
        <div>
          <h2>Introducción de bonos</h2>
          <p>Consulta el estado de tus fichajes en tiempo real y añade, modifica o elimina cualquier bono.
          </p>
        </div>
        <a class="home-button" (click)="accesoBonos()">Acceder</a>
      </article>
      <article id="home-dietas" class="home-card big image-fondo">
        <div>
          <h2>Dietas y gastos</h2>
          <p>Consulta el estado de tus gastos en tiempo real y añade, modifica o elimina cualquier
            destajo.
            <!-- <br><br>Integración con Sabbatic desde la plataforma.-->
          </p>
        </div>
        <a class="home-button" (click)="accesoDietas()">Acceder</a>
      </article>
    </div>
  </section>
  <section>
    <h2 style="margin-bottom: 30px;">Enlaces de interés</h2>
    <div class="home-flex">
      <article class="home-card small" style="background-color: #fff; color:#444444;"><a
          href="https://soporte.spyro.es/" target="_blank">
          <h2>Área cliente</h2>
        </a></article>
      <article class="home-card small" style="background-color: #00964A;"><a href="https://www.spyrosoftware.com/"
          target="_blank">
          <h2>Web Spyro</h2>
        </a></article>
      <!-- <article class="home-card small" style="background-color: #00B3B8;"><a href="https://sabbatic.es/mis-gastos"
          target="_blank">
          <h2>Sabbatic</h2>
        </a></article> -->
    </div>
  </section>
</div>