// TODO: BRF tab component
/**
 * NOTAS: Los tabs ahora se guardan en un array dentro de tab en redux, pero 
 * la posición recogida para la tabla siempre es la 0. Esa parte hay que cambiarla
 */
import { OnInit, Component, ViewChild, ViewContainerRef, OnDestroy, HostBinding } from '@angular/core';
import { PerfilService } from '../../services/Perfilservice';
import { MenuService } from '../../services/MenuService';


@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    private perfilService: PerfilService;
    private menuService: MenuService;
    usuario: any;

    constructor(p_perfilService: PerfilService, p_menuService: MenuService) {
        this.perfilService = p_perfilService;
        this.menuService = p_menuService;

    }


    ngOnInit() {
        console.log("NG ON INIT DASHBOARD --->>")
        this.usuario = this.perfilService.activo
        console.log(this.usuario)
    }
    accesoBonos() {
        this.menuService.abreOpcion('040602')
    }

    accesoDietas() {
        this.menuService.abreOpcion('040603')
    }

}
