import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { StaticModule } from './static/static.module';
import { AuthInterceptor } from './core/AuthInterceptor';
import { CapturaFocoService } from './core/services/CapturaFocoService';
import { AppConfigService } from './core/services/AppConfigService';
import { ManejadorErroresGlobal } from './core/ManejadorErroresGlobal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        CoreModule,
        SharedModule,
        StaticModule,
        BrowserAnimationsModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [AppConfigService],
            useFactory: (appConfigService: AppConfigService) => {
                return () => {
                    return appConfigService.loadAppConfig();
                };
            }
        }, 
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        CapturaFocoService,
        { provide: ErrorHandler, useClass: ManejadorErroresGlobal }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
